import React from "react";
import { Form } from "react-bootstrap";

const CitySelector = ({ cities, onCityChange }) => {
    return (
        <>
            <Form.Label>Выберите город</Form.Label>
            <Form.Select aria-label="Выберите город" onChange={(e) => onCityChange(e.target.value)}>
                <option>Выберите город</option>
                {cities.map((city) => (
                    <option key={city.id} value={city.name}>
                        {city.name}
                    </option>
                ))}
            </Form.Select>
        </>
    );
};

export default CitySelector;
