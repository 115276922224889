// components/LoginPage.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://45.91.201.124:8082/api/auth/login', { username, password });
            if (response.status === 200) {
                navigate('/companies'); // Перенаправление при успешной авторизации
            }
        } catch (err) {
            setError('Ошибка аутентификации');
        }
    };

    return (
        <div className="container mt-5">
            <h2>Вход для администратора</h2>
            <form onSubmit={handleLogin}>
                <div className="mb-3">
                    <label className="form-label">Имя пользователя</label>
                    <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} required />
                </div>
                <div className="mb-3">
                    <label className="form-label">Пароль</label>
                    <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                <button type="submit" className="btn btn-primary">Войти</button>
            </form>
        </div>
    );
}

export default LoginPage;
