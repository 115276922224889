// components/CompanyListPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function CompanyListPage() {
    const [companies, setCompanies] = useState([]);
    const [newCompany, setNewCompany] = useState({
        companyId: '',
        companyName: '',
        prefix: '',
        dataSourceUrl: ''
    });

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const response = await axios.get('http://45.91.201.124:8082/admin/company/list');
            setCompanies(response.data);
        } catch (error) {
            console.error('Ошибка при загрузке списка компаний:', error);
        }
    };

    const addCompany = async (e) => {
        e.preventDefault();
        try {
            await axios.post('http://45.91.201.124:8082/admin/company/add', newCompany, {
                withCredentials: true
            });
            setNewCompany({ companyId: '', companyName: '', prefix: '', dataSourceUrl: '' });
            fetchCompanies(); // Обновление списка после добавления
        } catch (error) {
            console.error('Ошибка при добавлении компании:', error);
        }
    };

    const deleteCompany = async (id) => {
        try {
            await axios.delete(`http://45.91.201.124:8082/admin/company/delete/${id}`, {
                withCredentials: true
            });
            fetchCompanies();
        } catch (error) {
            console.error('Ошибка при удалении компании:', error);
        }
    };

    return (
        <div className="container mt-5">
            <h2>Компании парковок</h2>

            <form onSubmit={addCompany}>
                {/*<div className="mb-3">*/}
                {/*    <label className="form-label">ID компании</label>*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        className="form-control"*/}
                {/*        value={newCompany.companyId}*/}
                {/*        onChange={(e) => setNewCompany({ ...newCompany, companyId: e.target.value })}*/}
                {/*        */}
                {/*    />*/}
                {/*</div>*/}
                <div className="mb-3">
                    <label className="form-label">Название компании</label>
                    <input
                        type="text"
                        className="form-control"
                        value={newCompany.companyName}
                        onChange={(e) => setNewCompany({ ...newCompany, companyName: e.target.value })}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Префикс</label>
                    <input
                        type="text"
                        className="form-control"
                        value={newCompany.prefix}
                        onChange={(e) => setNewCompany({ ...newCompany, prefix: e.target.value })}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">URL источника данных</label>
                    <input
                        type="text"
                        className="form-control"
                        value={newCompany.dataSourceUrl}
                        onChange={(e) => setNewCompany({ ...newCompany, dataSourceUrl: e.target.value })}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-success">Добавить компанию</button>
            </form>

            <ul className="list-group mt-4">
                {companies.map((company) => (
                    <li key={company.companyId} className="list-group-item">
                        <div><strong>ID компании:</strong> {company.companyId}</div>
                        <div><strong>Название:</strong> {company.companyName}</div>
                        <div><strong>Префикс:</strong> {company.prefix}</div>
                        <div><strong>URL источника данных:</strong> {company.dataSourceUrl}</div>
                        <button
                            className="btn btn-danger btn-sm mt-2"
                            onClick={() => deleteCompany(company.companyId)}
                        >
                            Удалить
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default CompanyListPage;
