import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Card, Button } from "react-bootstrap";

const ParkingDetails = () => {
    const { id } = useParams(); // Получаем ID парковки из URL
    const [parkingDetails, setParkingDetails] = useState(null);

    useEffect(() => {
        // Получаем данные о парковке по ID
        axios.get(`http://45.91.201.124:8082/parkings/${id}`)
            .then(response => setParkingDetails(response.data))
            .catch(error => console.error("Error fetching parking details:", error));
    }, [id]);

    if (!parkingDetails) {
        return <p>Загрузка...</p>;
    }

    return (
        <Container className="mt-4">
            <Card>
                <Card.Header as="h5">{parkingDetails.parkingName}</Card.Header>
                <Card.Body>
                    <Card.Title>Адрес: {parkingDetails.address}</Card.Title>
                    <Card.Text>
                        Свободных мест: {parkingDetails.availableSpots} / {parkingDetails.totalSpots}
                    </Card.Text>
                    <Card.Text>
                        Координаты: {parkingDetails.location}
                    </Card.Text>
                    {/* Кнопка для возвращения на карту */}
                    <Button variant="primary" href="/">Назад на карту</Button>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default ParkingDetails;
