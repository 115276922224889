import React, { useState, useEffect } from 'react';
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { Modal, Button, Form, Spinner, Toast } from 'react-bootstrap';
import axios from "axios";

const ParkingMap = ({ parkingLots }) => {
    const [selectedParking, setSelectedParking] = useState(null);
    const [showSubscribeModal, setShowSubscribeModal] = useState(false);
    const [showForecastModal, setShowForecastModal] = useState(false);
    const [email, setEmail] = useState('');
    const [forecastDate, setForecastDate] = useState('');
    const [forecastResult, setForecastResult] = useState(null);
    const [loading, setLoading] = useState(false); // Для отслеживания состояния загрузки
    const [showToast, setShowToast] = useState(false); // Для уведомлений
    const [toastMessage, setToastMessage] = useState(''); // Сообщение для уведомления

    const getMapCenter = (parkingLots) => {
        let totalLat = 0;
        let totalLon = 0;
        parkingLots.forEach(parking => {
            const [lat, lon] = parking.location.split(',').map(Number); // Разделяем строку на широту и долготу
            totalLat += lat;
            totalLon += lon;
        });
        return [totalLat / parkingLots.length, totalLon / parkingLots.length];
    };

    const center = getMapCenter(parkingLots);

    const handleMarkerClick = (parking) => {
        setSelectedParking(parking);
        setShowSubscribeModal(true);
    };

    const handleSubscribe = () => {
        if (!email) {
            setToastMessage("Пожалуйста, введите email");
            setShowToast(true);
            return;
        }

        setLoading(true); // Показываем спиннер загрузки
        const requestBody = {
            parkingLotId: selectedParking.parkingId,
            email: email
        };

        axios.post('http://45.91.201.124:8082/parkings/subscribe', null, { params: requestBody })
            .then(() => {
                setToastMessage(`Подписка оформлена на парковку: \n${selectedParking.parkingName}`);
                setShowToast(true);
            })
            .catch(error => {
                console.error('Ошибка при подписке:', error);
                setToastMessage('Произошла ошибка при оформлении подписки');
                setShowToast(true);
            })
            .finally(() => {
                setLoading(false); // Убираем спиннер загрузки
                setShowSubscribeModal(false);
            });
    };

    const handleForecast = () => {
        if (!forecastDate) {
            setToastMessage("Пожалуйста, введите дату и время");
            setShowToast(true);
            return;
        }

        setLoading(true);
        const formattedDate = `${forecastDate}:00`;
        const requestBody = {
            cityName: selectedParking.cityName,
            dateTime: formattedDate
        };

        console.log(requestBody);

        axios.post('http://45.91.201.124:8082/forecast/parking', requestBody)
            .then(response => {
                const forecastData = response.data;
                const formattedDate = new Date(forecastData.forecastDateTime).toLocaleString();
                const forecastRes = forecastData.parkingLotForecasts
                    .filter(forecast => forecast.parkingName === selectedParking.parkingName)[0];
                console.log(forecastRes);
                setForecastResult(forecastRes);
                setToastMessage(`Прогноз на ${formattedDate}:\nПредсказанное количество свободных мест: ${forecastRes.predictedAvailableSpots} из ${forecastRes.totalSpots}`);
                setShowToast(true);
            })
            .catch(error => {
                console.error('Ошибка при запросе прогноза:', error);
                setToastMessage('Произошла ошибка при запросе прогноза');
                setShowToast(true);
            })
            .finally(() => {
                setLoading(false);
                setShowForecastModal(false);
            });
    };

    const openForecastModal = (parking) => {
        setSelectedParking(parking);
        setShowForecastModal(true);
    };

    return (
        <div>
            <YMaps query={{ apikey: 'ВАШ_API_КЛЮЧ' }}>
                <Map state={{ center: center, zoom: 9 }} style={{ width: '100%', height: '600px' }}>
                    {parkingLots.map((parking, index) => {
                        const [lat, lon] = parking.location.split(',').map(Number);

                        if (isNaN(lat) || isNaN(lon)) {
                            console.error(`Invalid coordinates for parking at index ${index}:`, parking);
                            return null;
                        }

                        return (
                            <Placemark
                                key={parking.parkingId}
                                geometry={[lat, lon]}
                                properties={{
                                    hintContent: `Нажмите для информации о ${parking.parkingName}`,
                                    balloonContent: `
                                        <strong>${parking.parkingName}</strong><br />
                                        Адрес: ${parking.address}<br />
                                        Свободных мест: ${parking.availableSpots} / ${parking.totalSpots}<br />
                                        <button id="subscribeButton" class="btn btn-primary btn-sm">Подписаться</button>
                                        <button id="forecastButton" class="btn btn-secondary btn-sm">Прогноз</button>
                                    `,
                                }}
                                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                onBalloonOpen={() => {
                                    document.getElementById('subscribeButton').addEventListener('click', () => handleMarkerClick(parking));
                                    document.getElementById('forecastButton').addEventListener('click', () => openForecastModal(parking));
                                }}
                            />
                        );
                    })}
                </Map>
            </YMaps>

            {/* Модальное окно для подписки */}
            <Modal show={showSubscribeModal} onHide={() => setShowSubscribeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Подписка на обновления парковки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="email">
                            <Form.Label>Введите ваш email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Введите email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                    {loading && <Spinner animation="border" role="status"><span className="sr-only">Загрузка...</span></Spinner>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSubscribeModal(false)}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={handleSubscribe}>
                        Подписаться
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Модальное окно для прогнозирования */}
            <Modal show={showForecastModal} onHide={() => setShowForecastModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Прогноз для {selectedParking?.parkingName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="forecastDate">
                            <Form.Label>Введите дату и время для прогноза</Form.Label>
                            <Form.Control
                                type="datetime-local"
                                value={forecastDate}
                                onChange={(e) => setForecastDate(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                    {loading &&
                        <div className="d-flex flex-column align-items-center">
                            <Spinner animation="border" role="status"/>
                            <span className="mt-2">Загрузка...</span>
                        </div>}
                    {forecastResult && (
                        <div>
                            <h5>Прогноз:</h5>
                            <p>Свободных мест: {forecastResult.predictedAvailableSpots} / {forecastResult.totalSpots}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowForecastModal(false)}>
                        Закрыть
                    </Button>
                    <Button variant="primary" onClick={handleForecast}>
                        Получить прогноз
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Уведомление (Toast) */}
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={10000}
                autohide
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '20px',
                    zIndex: 9999
                }}
            >
                <Toast.Header>
                    <strong className="mr-auto">Уведомление</strong>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </div>
    );
};

export default ParkingMap;
