import React, { useState, useEffect } from "react";
import axios from "axios";
import ParkingMap from "./ParkingMap";
import CitySelector from "./CitySelector";
import { Container, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ParkingDetails from "./ParkingDetails";
import LoginPage from "./LoginPage";
import CompanyListPage from "./CompanyListPage";

const App = () => {
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState("Казань");
    const [parkingLots, setParkingLots] = useState([]);
    const [mapCenter, setMapCenter] = useState([54.3142, 48.4031]); // Координаты Ульяновска по умолчанию
    axios.defaults.withCredentials = true;

    useEffect(() => {
        axios.get("http://45.91.201.124:8082/cities")
            .then(response => setCities(response.data))
            .catch(error => console.error("Error fetching cities:", error));
    }, []);

    useEffect(() => {
        axios.get(`http://45.91.201.124:8082/parkings/city/${selectedCity}`)
            .then(response => {
                setParkingLots(response.data);
                if (response.data.length > 0) {
                    const firstParkingLocation = response.data[0].location.split(',').map(Number);
                    setMapCenter(firstParkingLocation);
                }
            })
            .catch(error => console.error("Error fetching parking lots:", error));
    }, [selectedCity]);

    return (
        <Router>
            <Routes>
                {/* Главная страница с картой парковок и выбором города */}
                <Route
                    path="/"
                    element={
                        <Container className="mt-4">
                            <Row>
                                <Col>
                                    <h1 className="text-center">Карта парковок</h1>
                                    <CitySelector cities={cities} onCityChange={setSelectedCity} />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <ParkingMap parkingLots={parkingLots} />
                                </Col>
                            </Row>
                        </Container>
                    }
                />

                {/* Страница деталей парковки */}
                <Route
                    path="/parking/:id"
                    element={
                        <Container className="mt-4">
                            <ParkingDetails />
                        </Container>
                    }
                />

                {/* Страница авторизации */}
                <Route
                    path="/login"
                    element={
                        <Container className="mt-4">
                            <LoginPage />
                        </Container>
                    }
                />

                {/* Страница списка компаний парковок */}
                <Route
                    path="/companies"
                    element={
                        <Container className="mt-4">
                            <CompanyListPage />
                        </Container>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;
